import { AppointmentStatus } from "@prisma/client"
import { FlatEvent } from "@sequel-care/types"
import { Toggle } from "components/common"
import DashboardCard from "components/DashboardCard"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { setPastAppointmentStatus } from "store/global/actions"
import { useCurrentUser, usePastAppointments } from "store/hooks"
import { getUserName } from "utils"
import { localeFormat } from "utils/dates"
import AppointmentStatusButtons from "./AppointmentStatusButtons"

const PastAppointments = () => {
    const { t } = useTranslation("therapist")
    const appointments = usePastAppointments()
    const minItemsToDisplay = 3

    return !appointments || !appointments.length ? null : (
        <DashboardCard title={t("homePage.pastMeetings")} subtitle={t("homePage.pastMeetingsDescription")}>
            <Toggle>
                <Toggle.Content className="flex flex-col divide-y" minChildrenToDisplay={minItemsToDisplay}>
                    {appointments.map((appointment) => (
                        <AppointmentRow key={appointment.id + appointment.date_on_timeline} {...appointment} />
                    ))}
                </Toggle.Content>
                {minItemsToDisplay < appointments.length && (
                    <Toggle.Button
                        chevron={{ closedRotation: "bottom", openRotation: "top" }}
                        showChevron
                        className="!justify-end gap-2 text-sm items-center hover:underline underline-offset-4"
                    >
                        {({ isOpen }) => t(`homePage.${isOpen ? "showLess" : "showMore"}`)}
                    </Toggle.Button>
                )}
            </Toggle>
        </DashboardCard>
    )
}

export default PastAppointments

const AppointmentRow = ({ id, invitees, date_on_timeline, appointment_status }: FlatEvent<string>) => {
    const currentUser = useCurrentUser()
    const { t, i18n } = useTranslation("therapist")
    const [loading, setLoading] = useState(null)
    const dispatch = useDispatch()

    const inviteeString = useMemo(
        () =>
            invitees
                .filter(({ id }) => id !== currentUser.id)
                .map((i) => getUserName(i))
                .join(", "),
        [invitees]
    )

    const setStatus = async (status: AppointmentStatus) => {
        setLoading(status)
        const res = await dispatch(setPastAppointmentStatus(id, status, date_on_timeline))
        if (!res) toast(t("common:genericError"))
        setLoading(null)
    }

    return (
        <div className="flex justify-between py-4">
            <div className="flex flex-col text-dark-blue">
                {inviteeString}
                {<span className="text-secondary-2">{localeFormat(new Date(date_on_timeline), "MMM d, p", i18n)}</span>}
            </div>
            <div className="flex gap-2 items-center">
                <AppointmentStatusButtons
                    {...{ loading, setStatus }}
                    status={appointment_status}
                    size="small"
                    roundedClass={"rounded-2xl"}
                />
            </div>
        </div>
    )
}

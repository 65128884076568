import { AppointmentStatus } from "@prisma/client"
import { EventsByDate, FlatEvent } from "@sequel-care/types"
import { isBefore, isSameDay } from "date-fns"
import { TFunction, useTranslation } from "react-i18next"
import Skeleton from "react-loading-skeleton"
import { useDispatch } from "react-redux"
import { pushEventSidebarToStack } from "store/sidebars/actions"
import { LastArrayElement } from "type-fest"
import { getAppointmentEndTime, localeFormat } from "utils/dates"
import { useCurrentUser, useUpcomingAppointments } from "../../store/hooks"
import { getUserName } from "../../utils"
import DashboardCard from "../DashboardCard"
import { Tag, TagColor } from "../common/Tag"

const statusBadge: { [key in AppointmentStatus]: TagColor } = {
    occured: "green",
    canceled: "red",
    not_showed: "yellow"
}

const getStatusBadge = (status: AppointmentStatus, t: TFunction, end_time?: Date) => {
    if (status) {
        return (
            <Tag color={statusBadge[status] as string}>
                {t(`therapist:homePage.appointmentsStatusLabels.${status}`)}
            </Tag>
        )
    } else {
        return end_time && isBefore(end_time, new Date()) ? (
            <Tag color="green">{t("patient:completed")}</Tag>
        ) : (
            <Tag color="yellow">{t("upcoming")}</Tag>
        )
    }
}

const AppointmentItem = ({ appointment, isToday }: { appointment: FlatEvent<string>; isToday?: boolean }) => {
    const currentUser = useCurrentUser()
    const { i18n, t } = useTranslation("common")
    const dispatch = useDispatch()
    const { id, patient_id, date_on_timeline, appointment_status, end_time } = appointment

    return (
        <div
            className="flex justify-between py-5 cursor-pointer"
            onClick={() =>
                dispatch(
                    pushEventSidebarToStack({
                        date_on_timeline: new Date(date_on_timeline),
                        id,
                        patient_id,
                        context: {
                            context: "notification",
                            time: new Date()
                        }
                    })
                )
            }
        >
            <div className="flex text-base font-medium">
                <div className="mr-4">{localeFormat(new Date(date_on_timeline), "p", i18n)}</div>
                <div className="text-dark-blue">
                    {appointment.invitees
                        .filter(({ id }) => id !== currentUser.id)
                        .map((i) => getUserName(i))
                        .join(", ")}
                </div>
            </div>
            <div>{getStatusBadge(appointment_status, t, isToday ? getAppointmentEndTime(appointment) : null)}</div>
        </div>
    )
}
const AppointmentBlock = ({ date, events }: LastArrayElement<EventsByDate<string>>) => {
    const { t } = useTranslation("dates")
    const isToday = isSameDay(new Date(), new Date(date))
    return (
        <div className="text-secondary text-sm">
            {t(isToday ? "today" : "tomorrow")}
            <div className="divide-y divide-border-blue">
                {events.map((appointment, index) => {
                    return <AppointmentItem key={index} appointment={appointment} isToday={isToday} />
                })}
            </div>
        </div>
    )
}

const UpcomingAppointments = ({ loading }: { loading: Boolean }) => {
    const appointments = useUpcomingAppointments()
    const { t } = useTranslation("common")

    return (
        <DashboardCard title={t("upcoming_appointments")}>
            {loading ? (
                <div className="flex flex-col gap-2 text-secondary text-sm">
                    <Skeleton className="w-16 h-4" />
                    <div className="flex flex-col gap-4 divide-y">
                        {new Array(3).fill(0).map((_, index) => (
                            <div key={index} className="flex justify-between items-center pt-4">
                                <Skeleton className="h-6 w-48" />
                                <Skeleton className="h-6 w-32" />
                            </div>
                        ))}
                    </div>
                </div>
            ) : appointments.length !== 0 ? (
                appointments.map((appointmentOnDate) => (
                    <AppointmentBlock key={appointmentOnDate.date} {...appointmentOnDate} />
                ))
            ) : (
                <div className="flex flex-col items-center m-14">
                    {t("no_appointments_title")}
                    <span className="text-secondary-2">{t("no_appointments_subtitle")}</span>
                </div>
            )}
        </DashboardCard>
    )
}

export default UpcomingAppointments

import { PatientWithMetrics } from "@sequel-care/types/Patient"
import DashboardCard from "components/DashboardCard"
import { useTranslation } from "react-i18next"
import Skeleton from "react-loading-skeleton"

export const TreatmentLength = ({
    patients,
    loading
}: {
    patients: PatientWithMetrics<string>[]
    loading: boolean
}) => {
    const { t } = useTranslation("therapist")
    const { averageLength, min, max } = patients.reduce<{ averageLength: number; min: number; max: number }>(
        (acc, { metrics }, index) => {
            const { treatment_length } = metrics
            if (treatment_length > acc.max) acc.max = treatment_length
            if (treatment_length < acc.min) acc.min = treatment_length

            acc.averageLength += metrics.treatment_length
            if (index === patients.length - 1) acc.averageLength = Math.round((acc.averageLength /= patients.length))
            return acc
        },
        { averageLength: 0, min: 0, max: 0 }
    )

    return (
        <DashboardCard
            title={loading ? <Skeleton className="w-32" /> : t("dashboard.treatmentLength.title")}
            contentClassName="flex flex-col gap-6 text-med-blue"
            className="h-fit"
        >
            <div className="bg-sidebar flex flex-col gap-3 py-10 text-center font-meidum">
                {loading ? (
                    <Skeleton className="w-1/3" />
                ) : (
                    <span className="text-2.5xl font-semibold">
                        {t("dashboard.treatmentLength.value", { length: averageLength })}
                    </span>
                )}
                {loading ? <Skeleton className="w-1/2" /> : t("dashboard.treatmentLength.averageLength")}
            </div>
            <div className="bg-sidebar flex flex-col gap-3 py-10 text-center font-medium">
                {loading ? (
                    <Skeleton className="w-1/3" />
                ) : (
                    <span className="text-2.5xl">
                        {t("dashboard.treatmentLength.value", { length: `${min}-${max}` })}
                    </span>
                )}
                {loading ? <Skeleton className="w-1/2" /> : t("dashboard.treatmentLength.minMaxLength")}
            </div>
        </DashboardCard>
    )
}

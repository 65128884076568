import { PatientWithMetrics } from "@sequel-care/types/Patient"
import DashboardCard from "components/DashboardCard"
import { ReactNode, useMemo } from "react"
import { useTranslation } from "react-i18next"
import Skeleton from "react-loading-skeleton"
import {
    BarHorizontalChart,
    BarHorizontalChartWithInteractions,
    CircleChart,
    CircleChartWithInteractions
} from "../ChartComponents"
import { getHighBurdenPatients, highBurdenIndicators } from "../utils"

export const HighBurden = ({
    patients,
    withInteractions,
    emptyState,
    loading
}: {
    patients: PatientWithMetrics<string>[]
    withInteractions?: boolean
    emptyState?: ReactNode
    loading: boolean
}) => {
    const { t } = useTranslation("therapist")
    const total = patients.length

    const { patientsByMetric, value } = useMemo(() => {
        const { patientsByMetric, uniquePatients } = getHighBurdenPatients(patients)
        return { patientsByMetric, value: uniquePatients.length }
    }, [patients])

    // Yoni's calculation
    // const value = Math.round((countByMetric.reduce((acc, [_, count]) => (acc += count), 0) / 3) * 10) / 10

    const CircleComponent = withInteractions ? CircleChartWithInteractions : CircleChart
    const BarComponent = withInteractions ? BarHorizontalChartWithInteractions : BarHorizontalChart

    return (
        <DashboardCard
            title={loading ? <Skeleton className="w-32" /> : t("dashboard.highBurden.title")}
            subtitle={loading ? <Skeleton className="w-64" /> : t("dashboard.highBurden.subtitle")}
            contentClassName="flex flex-col gap-12 items-center mt-8 mb-3"
        >
            {emptyState ? (
                emptyState
            ) : (
                <>
                    <CircleComponent
                        loading={loading}
                        label={t("dashboard.highBurden.title")}
                        size="large"
                        value={value}
                        value_out_of={total}
                        color="text-negative"
                        filterdata={
                            !withInteractions
                                ? undefined
                                : { high_burden: true, title: t(`listSubtitles.highBurden.all`) }
                        }
                    />
                    <div className="w-full flex flex-col gap-5">
                        {patientsByMetric.map(([metric, patients]) => (
                            <BarComponent
                                key={metric}
                                color="#E5596A"
                                value={patients.length}
                                value_out_of={total}
                                label={t(`dashboard.highBurden.${metric}`)}
                                withBackground
                                filterdata={
                                    !withInteractions
                                        ? undefined
                                        : {
                                              high_burden: true,
                                              key: metric,
                                              value: highBurdenIndicators[metric],
                                              title: t(`listSubtitles.highBurden.${metric}`)
                                          }
                                }
                            />
                        ))}
                    </div>
                </>
            )}
        </DashboardCard>
    )
}

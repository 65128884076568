import { PatientWithDiagnoses } from "@sequel-care/types"
import { getAllPatients } from "api"
import { Button } from "components/common"
import CloudImg from "components/common/CloudImg"
import StatusFilter from "components/Exports/StatusFilter"
import TherapistFilter from "components/Exports/TherapistFilter"
import { PlusIcon } from "components/icons"
import { EditPatientAction, EndTreatmentAction, PatientFilters, patientListFields } from "components/PatientList/utils"
import Search from "components/Search"
import { ifUserDisabled, ItemGrid } from "components/Settings/ItemList"
import TopBar from "components/TopBar"
import { TagSelect } from "forms/SuperSelect/wrappers"
import { NextPage } from "next"
import Link from "next/link"
import { useRouter } from "next/router"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useCurrentRole, usePatientList, useIsExternalTherapist } from "store/hooks"
import { classNames, createSetter, getUserName } from "utils"
import { useDispatch } from "../../store"
import { openPatientModal } from "../../store/modal/actions"
import { FeatureFlags } from "../../utils/FeatureFlags"

const labelKeys = ["patientName", "patientStatus", "tags", "actions"]
export type ListPatient = PatientWithDiagnoses<string, string>

const Patients: NextPage = function Patients() {
    const { t } = useTranslation("patient")
    const router = useRouter()
    const currentRole = useCurrentRole()
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)
    const onlyMyPatients = router.asPath.endsWith("#my-patients")
    const myPatients = usePatientList()
    const [allPatientUsers, setAllPatientUsers] = useState<ListPatient[]>([])
    const [filters, setFilters] = useState<PatientFilters>({
        tags: [],
        status: undefined,
        query: "",
        therapist_ids: []
    })
    function onFilterChange<K extends keyof PatientFilters>(key: K) {
        return createSetter(setFilters, key)
    }

    const isAdmin = currentRole?.role === "admin"
    const isExternalTherapist = useIsExternalTherapist()
    const users: ListPatient[] = !loading ? (onlyMyPatients || !isAdmin ? myPatients : allPatientUsers) : null

    const filteredPatients = useMemo(
        () =>
            users
                ?.filter(({ collaborators, status, tags, user }) => {
                    const therapistMatch =
                        !filters.therapist_ids.length ||
                        collaborators.some(
                            ({ user, permissions }) => filters.therapist_ids.includes(user.id) && permissions.length
                        )
                    const tagMatch = !filters.tags.length || tags.some(({ id }) => filters.tags.includes(id))
                    const statusMatch = !filters.status || status === filters.status
                    const query = filters.query.toLowerCase()
                    const userName = getUserName(user)
                    const queryMatch =
                        user.email.toLowerCase().includes(query) || userName.toLowerCase().includes(query)

                    return statusMatch && therapistMatch && tagMatch && queryMatch
                })
                .sort((a, b) => (a.status === "past" ? 1 : b.status === "past" ? -1 : b.id - a.id)),
        [users, filters]
    )

    useEffect(() => {
        const fetchAllPatients = async () => {
            try {
                setLoading(true)
                const allPatients = await getAllPatients()
                setAllPatientUsers(allPatients)
            } catch (error) {
                console.error(error)
                // Nothing to do here
            } finally {
                setLoading(false)
            }
        }
        if (isAdmin && !onlyMyPatients && !allPatientUsers.length) fetchAllPatients()
    }, [isAdmin, onlyMyPatients])

    const { leftSection, labels } = useMemo(() => {
        const pages = [
            { title: t("allPatients"), href: `/patients` },
            { title: t("myPatients"), href: `patients/#my-patients` }
        ]
        return {
            leftSection: {
                children: pages?.map(({ title, href }) => (
                    <Link href={href} key={title} passHref>
                        <a
                            className={classNames(
                                "cursor-pointer font-semibold",
                                router.asPath === href ? "text-dark-blue" : "text-secondary hover:text-dark-blue"
                            )}
                        >
                            {title}
                        </a>
                    </Link>
                ))
            },
            labels: labelKeys.map((key) => t(key))
        }
    }, [t, router.asPath])

    const patientActions = [
        EditPatientAction(currentRole, setAllPatientUsers),
        EndTreatmentAction(currentRole, setAllPatientUsers)
    ]

    const isOverviewEnabled = FeatureFlags.isEnabled("overview")

    return (
        <>
            {users?.length || users === null ? (
                <>
                    <TopBar
                        title={!isAdmin ? t("myPatients") : t("patients")}
                        className="mt-5 mb-10"
                        leftSection={leftSection}
                    >
                        {!isExternalTherapist && (
                            <Button
                                theme="primary"
                                onClick={() => dispatch(openPatientModal())}
                                className="flex items-center gap-2"
                                data-testid="new-patient"
                            >
                                <PlusIcon className="w-4" /> {t("newPatient")}
                            </Button>
                        )}
                    </TopBar>
                    <div className="px-10 flex flex-col gap-16 h-5/6">
                        <div className="flex gap-4">
                            <TherapistFilter
                                value={filters.therapist_ids}
                                setTherapists={onFilterChange("therapist_ids")}
                            />
                            <StatusFilter status={filters.status} setStatus={onFilterChange("status")} />
                            <TagSelect
                                id={"tags"}
                                value={filters.tags}
                                setValue={onFilterChange("tags")}
                                style="classic"
                                placeholder={t("therapist:dashboard.filters.allTags")}
                                usePlaceholderAsAll
                                classList={{
                                    input: "w-64 px-4 py-3 h-[3.125rem] !text-base  border border-border-blue"
                                }}
                            />
                            <Search
                                search={filters.query}
                                setSearch={onFilterChange("query")}
                                wrapperClassname="!text-dark-blue border border-border-blue"
                            />
                        </div>
                        <ItemGrid
                            items={filteredPatients}
                            onItemClick={(user: ListPatient) =>
                                router.push(`/patient/${user.id}/${isOverviewEnabled ? "overview" : "timeline"}`)
                            }
                            ifDisabled={ifUserDisabled}
                            fields={patientListFields}
                            actions={patientActions}
                            labels={labels}
                            labelsBg="bg-white"
                            gridTemplateColumns="2fr 1fr 3fr 0.3fr"
                        />
                    </div>
                </>
            ) : (
                <div className="font-semibold max-w-sm flex flex-col pt-20 items-center text-center h-full mx-auto">
                    <CloudImg variant={"large"} imageId="empty-timeline" width="240" height="240" />
                    <h1 className="text-3xl text-dark-blue mt-8 mb-2.5">{t("noPatients")}</h1>
                    {!isExternalTherapist && (
                        <>
                            <h2 className="text-xl text-text-blue mb-7">{t("goAddPatients")}</h2>
                            <Button
                                className="font-bold w-52 p-4"
                                theme="primary"
                                onClick={() => dispatch(openPatientModal())}
                            >
                                {t("addPatient")}
                            </Button>
                        </>
                    )}
                </div>
            )}
        </>
    )
}

export default Patients

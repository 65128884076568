import { Metric } from "@sequel-care/types"
import { PatientWithMetrics } from "@sequel-care/types/Patient"
import DashboardCard from "components/DashboardCard"
import { camelCase, keyBy, uniq } from "lodash"
import { useTranslation } from "react-i18next"
import Skeleton from "react-loading-skeleton"
import { BarHorizontalChart, BarHorizontalChartWithInteractions } from "../ChartComponents"
import { filterPatientsByMetric, tableColors } from "../utils"

export function BreakdownTable<K extends keyof Metric>({
    patients,
    metric,
    useItems,
    withInteractions = false,
    loading
}: {
    patients: PatientWithMetrics<string>[]
    metric: K
    useItems: () => { id: number; name: string }[]
    withInteractions?: boolean
    loading: boolean
}) {
    const { t } = useTranslation("therapist")
    const items = keyBy(useItems(), "id")
    const countByValue = uniq(patients.flatMap((patient) => patient.metrics[metric] as string | string[])).map(
        (value: string) => ({
            metricValue: value,
            name: items[value]?.name,
            count: value !== null ? filterPatientsByMetric(patients, metric, value as Metric[K]).length : 0
        })
    )

    const propI18nKey = camelCase(metric)

    const BarComponent = withInteractions ? BarHorizontalChartWithInteractions : BarHorizontalChart

    return (
        <DashboardCard
            title={loading ? <Skeleton className="w-32" /> : t(`dashboard.${propI18nKey}.title`)}
            subtitle={loading ? <Skeleton className="w-64" /> : t(`dashboard.${propI18nKey}.subtitle`)}
        >
            <div className="grid grid-cols-2 gap-y-6 gap-x-12">
                {countByValue.map(({ metricValue, count, name }, index) => {
                    if (!name) return null

                    const color = tableColors[index]

                    return (
                        <BarComponent
                            key={metricValue}
                            value={count}
                            value_out_of={patients.length}
                            label={name}
                            color={color}
                            withBackground
                            filterdata={
                                !withInteractions
                                    ? undefined
                                    : {
                                          key: metric,
                                          value: metricValue as Metric[K],
                                          color
                                      }
                            }
                        />
                    )
                })}
            </div>
        </DashboardCard>
    )
}

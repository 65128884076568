import { PatientStatus, RoleWithOrg } from "@sequel-care/types"
import { getAllPatients } from "api"
import { Button } from "components/common"
import EditIcon from "components/icons/Edit"
import { AvatarField } from "components/Settings/ItemList"
import { ListField } from "components/Settings/types"
import { ListPatient } from "pages/patients"
import { Dispatch, SetStateAction, useState } from "react"
import { TbUserOff, TbUserPlus } from "react-icons/tb"
import { useDispatch } from "react-redux"
import { setTreatmentEnd } from "store/global/actions"
import { openPatientModal, openTreatmentEndModal } from "store/modal/actions"
import { SkeletonPlaceholder } from "types/Misc"
import { classNames } from "utils"
import { StatusField, TagsField } from "./RowComponents"

export const patientListFields: ListField<ListPatient>[] = [
    {
        key: "user",
        RenderField: AvatarField
    },
    {
        key: "status",
        RenderField: StatusField
    },
    {
        key: "tags",
        RenderField: TagsField
    }
]

export type PatientFilters = {
    therapist_ids: number[]
    status?: PatientStatus
    tags: number[]
    query: string
}

export const EditPatientAction = (role: RoleWithOrg, setPatients: Dispatch<SetStateAction<ListPatient[]>>) => {
    const dispatch = useDispatch()

    return ({ item }: { item: ListPatient | SkeletonPlaceholder }) => {
        const isSkeleton = "skeleton" in item
        const isUser = "user" in item
        if (
            !isSkeleton &&
            role.role !== "admin" &&
            !item.collaborators.some(
                ({ user, permissions }) =>
                    user.id === role.user_id && permissions.some(({ permission }) => permission === "case_manager")
            )
        )
            return null

        const onClick = () => {
            if (isSkeleton) return

            dispatch(
                openPatientModal({
                    patientFile: item,
                    onUpdate: () => getAllPatients().then((res) => setPatients(res))
                })
            )
        }

        return (
            <EditIcon
                data-testid={isUser ? `edit-patient-btn-${item.user.id}` : "edit-patient-btn"}
                className={classNames("w-5 h-5", !isSkeleton && "cursor-pointer")}
                onClick={onClick}
            />
        )
    }
}

export const EndTreatmentAction = (role: RoleWithOrg, setPatients?: Dispatch<SetStateAction<ListPatient[]>>) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    return ({ item }: { item: ListPatient | SkeletonPlaceholder }) => {
        const isSkeleton = "skeleton" in item

        const openModal = () => {
            if (isSkeleton) return

            dispatch(
                openTreatmentEndModal({
                    patient_id: item.id,
                    fromPatientScope: false,
                    setPatients,
                    initialState: item.treatment_end
                        ? { reason: item.treatment_end_reason, date: new Date(item.treatment_end) }
                        : undefined
                })
            )
        }

        const activatePatient = async (item: ListPatient) => {
            setLoading(true)
            await dispatch(setTreatmentEnd(item.id, { date: null, reason: undefined }, false, setPatients))
            setLoading(false)
        }

        if (
            role.role === "admin" ||
            (item as ListPatient).collaborators.some(({ permissions }) => {
                return permissions?.some(({ user_id, permission }) => user_id == role.user_id && permission === "case_manager")}
            )
        ) {
            if (!isSkeleton && item.treatment_end)
                return (
                    <Button loading={loading} onClick={() => activatePatient(item)}>
                        <TbUserPlus className="w-5 h-5" />
                    </Button>
                )

            return (
                <TbUserOff
                    onClick={openModal}
                    className={classNames("w-5 h-5", !isSkeleton && "cursor-pointer")}
                    data-testid={isSkeleton ? "end-treatment-btn" : `end-treatment-btn-${item.id}`}
                />
            )
        } else return null
    }
}

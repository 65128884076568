import { Button, Toggle } from "components/common"
import Badge from "components/common/Badge"
import DashboardCard from "components/DashboardCard"
import NotificationItem from "components/Notifications/NotificationItem"
import { useNotificationRanges } from "components/Notifications/utils"
import { ActivityCard, Caseload, HighBurden, TrendProgress } from "components/TherapistDashboard/Cards"
import { useDashboardPatients } from "components/TherapistDashboard/utils"
import PastAppointments from "components/TherapistHomepage/PastAppointments"
import { addDays } from "date-fns"
import { uniq, upperFirst } from "lodash"
import { NextPage } from "next"
import Link from "next/link"
import { useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { fetchUserAppointments } from "store/global/actions"
import { useCurrentUser, useNotifications, useNotificationsLoading, useUnread } from "store/hooks"
import { markAsRead } from "store/notifications/actions"
import { NotificationCategories } from "types/Misc"
import { classNames, getUserName } from "utils"
import { createFetchRange, localeFormat } from "utils/dates"
import { EventUpdateTracker } from "utils/EventUpdateTracker"
import { FeatureFlags } from "utils/FeatureFlags"
import UpcomingAppointments from "../components/TherapistHomepage/UpcomingAppointments"
import Patients from "./patients"

const Home: NextPage = function Home() {
    const { t, i18n } = useTranslation("therapist")
    const user = useCurrentUser()
    const { patients, loading } = useDashboardPatients("current", "case_manager")
    const dispatch = useDispatch()
    const [appointmentLoading, setLoading] = useState(true)

    useEffect(() => {
        const doFetch = async () => {
            setLoading(true)
            const { start, end } = createFetchRange(({ dayEnd, dayStart }) => {
                return { start: dayStart, end: addDays(dayEnd, 1) }
            })
            await dispatch(fetchUserAppointments(start, end))
            setLoading(false)
        }

        doFetch()
        return EventUpdateTracker.subscribe(({ type }) => type === "appointment" && doFetch())
    }, [])

    if (FeatureFlags.isDisabled("therapist-homepage")) return <Patients />

    return (
        <div className="p-10 bg-bg flex flex-col gap-11 text-dark-blue">
            <div className="flex flex-col gap-4">
                <h1 className="text-3xl font-semibold">{t("homePage.welcomeBack", { fullName: getUserName(user) })}</h1>
                <span className="text-lg text-text-blue">{localeFormat(new Date(), "EEEE, MMMM do yyyy", i18n)}</span>
            </div>
            <div className="flex gap-6">
                <div className="flex flex-col gap-6 w-1/2">
                    <h2 className="text-2xl">{t("homePage.alerts")}</h2>
                    <NotificationCard category={NotificationCategories.Alerts} />
                    {FeatureFlags.isEnabled("appointment-events") && (
                        <>
                            <PastAppointments />
                            <UpcomingAppointments loading={appointmentLoading} />
                        </>
                    )}
                    <NotificationCard category={NotificationCategories.Comments} />
                </div>
                <div className="flex flex-col gap-6 w-1/2">
                    <div className="flex justify-between">
                        <h2 className="text-2xl">{t("homePage.patientData")}</h2>
                        <Link href={"/dashboard"} passHref>
                            <Button theme="secondary_borderless" className="!p-0 !text-base">
                                {t("common:learnMore")}
                            </Button>
                        </Link>
                    </div>
                    <div className="flex flex-col gap-6">
                        <TrendProgress loading={loading} patients={patients} metric={"primary_progress"} />
                        <HighBurden loading={loading} patients={patients} />
                        <ActivityCard loading={loading} patients={patients} filters={undefined} />
                        <Caseload loading={loading} patients={patients} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home

const NotificationCard = ({ category }: { category: NotificationCategories }) => {
    const { t } = useTranslation("therapist")
    const notifications = useNotifications(category)
    const notificationCount = useRef(0)
    const unread = useUnread()
    const [displayed, setDisplayed] = useState<number[]>([])
    const notificationsLoading = useNotificationsLoading()
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        if (notificationCount.current !== notifications.filter(({ read }) => !read).length) {
            if (!displayed.length) setDisplayed(notifications.filter(({ read }) => !read).map(({ id }) => id))
            else setDisplayed(uniq(displayed.concat(notifications.filter(({ read }) => !read).map(({ id }) => id))))
            notificationCount.current = displayed.length
        }
    }, [notifications])

    const markCategoryAsRead = async () => {
        setLoading(true)
        const unreadIds = notifications.map(({ id }) => id)

        await dispatch(markAsRead(unreadIds))
        setLoading(false)
        setDisplayed([])
    }

    const notificationRangeMapping = useNotificationRanges(notifications, t)
    const minItemsToDisplay = 3
    const notificationList = useMemo(
        () =>
            notificationsLoading
                ? new Array(minItemsToDisplay).fill(undefined)
                : notifications.filter(({ id }) => displayed.includes(id)),
        [notifications, notificationsLoading, displayed]
    )

    return (
        <DashboardCard
            title={
                <>
                    <span className="text-lg font-medium">{t(`homePage.unread${upperFirst(category)}`)}</span>
                    <Badge
                        displayNumber={unread[category].length}
                        visible={unread[category].length > 0}
                        wrapperClassNames="text-med-blue !text-xs"
                    />
                </>
            }
            headerRight={
                <Button
                    theme="secondary_borderless"
                    loading={loading}
                    onClick={markCategoryAsRead}
                    disabled={!displayed.length}
                    className={classNames(
                        !displayed.length && "!text-border-blue cursor-default",
                        "translate-y-1.5 !p-0"
                    )}
                >
                    {t("homePage.markAsRead")}
                </Button>
            }
            className="!p-5"
        >
            <div>
                {notificationsLoading || unread[category].length ? (
                    <Toggle>
                        <Toggle.Content
                            transitionDuration={1000}
                            minChildrenToDisplay={minItemsToDisplay}
                            key={notificationsLoading ? "loading" : "loaded"}
                        >
                            {notificationList.map((notification, index) => (
                                <NotificationItem
                                    key={notification?.id ?? index}
                                    notification={notification}
                                    rangeTitle={notificationRangeMapping[index]}
                                    withSeparator={!(index === 0 || Boolean(notificationRangeMapping[index]))}
                                />
                            ))}
                        </Toggle.Content>
                        {minItemsToDisplay < notificationList.length && (
                            <Toggle.Button
                                chevron={{ closedRotation: "bottom", openRotation: "top" }}
                                showChevron={true}
                                className="!justify-end gap-2 text-sm items-center hover:underline underline-offset-4"
                            >
                                {({ isOpen }) => t(`homePage.${isOpen ? "showLess" : "showMore"}`)}
                            </Toggle.Button>
                        )}
                    </Toggle>
                ) : (
                    <div className="flex flex-col items-center m-14">
                        {t("homePage.emptyStates.alerts.title")}
                        <span className="text-secondary-2">{t(`homePage.emptyStates.${category}.subtitle`)}</span>
                    </div>
                )}
            </div>
        </DashboardCard>
    )
}
